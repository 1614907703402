export class Config {
   // backendURL: string = "http://localhost:8000";
   //  backendURL: string = "https://drbb-test-back-end.com";
    backendURL: string = "https://drbb-back-end.com";

    endpoints = {
        // Auth endpoints
        "sign_in": "/auth/jwt/create/",
        "sign_up": "/auth/users/",
        "sign_out": "/auth/token/logout/",
        "about_me": "/auth/users/me/",
        "refresh_token": "/auth/jwt/refresh/",
        "verify_token": "/auth/jwt/verify/",
        "reset_password": "/api/password_reset/",
        "reset_password_confirm": "/api/password_reset/confirm/",
        "set_acquisition_source": "/auth/set-acquisition/",

        // User endpoints
        "change_password": "/auth/change_password/",
        "change_email": "/auth/change-email/",
        "change_phone": "/auth/change-phone/",
        "change_nickname": "/auth/change-nickname/",
        "delete_user": "/auth/delete-user/",

        // Mailchimp endpoints
        "mail_invite": "/mail/invite/",
        "manage_subscribe": "/mail/manage-subscribe/",
        "mail_settings": "/mail/settings/",

        // Driver endpoints
        "driver_list": "/api/driver-list/",
        "driver_detail": "/api/driver-detail/",
        "driver_stats" : "/api/driver-stats/",
        "result_view_all": "/api/result-summary/",

        // Pick endpoints
        "pick_detail": "/api/pick-detail/",
        "picks_by_event": "/api/picks-by-event/",
        "pick_delete": "/api/pick-delete/",
        "pick_bulk": "/api/pick-bulk/",
        "four_wide_pick_bulk": "/api/four-wide-pick-bulk/",
        "pick_stats": "/api/pick-stats/",
        "pick_copy": "/api/copy-picks-from-league/",

        // Result endpoints
        "result_by_event": "/api/result-by-event/",
        "result_delete": "/api/result-delete/",
        "result_create_or_update": "/api/result-create-or-update/",
        "result_detail": "/api/result/",

        // Event endpoints
        "event_list": "/api/event-list/",
        "event_by_season": "/api/events-by-season/",
        "event_detail": "/api/event-detail/",
        "event_delete": "/api/event-delete/",
        "event_upload_image": "/api/event-image-upload/",
        "event_left_sponsor_image_upload": "/api/event-image-left-sponsor-upload/",
        "event_right_sponsor_image_upload": "/api/event-image-right-sponsor-upload/",

        // Series endpoints
        "series_list": "/api/series-list/",
        "series_detail": "/api/series-detail/",
        "series_delete": "/api/series-delete/",
        "series_create_or_update": "/api/series-create-or-update/",


        // league endpoints
        "league_by_player": "/auth/league/",
        "league_detail": "/auth/league-detail/",
        "join_league": "/auth/join-league/",
        "league_create": "/auth/league-create/",
        "league_upload_photo": "/auth/league-upload-photo/",
        "league_update": "/auth/league-update/",
        "league_delete": "/auth/league-delete/",
        "invite_to_league": "/auth/invite-to-league/",
        "league_history_load": "/auth/league-history/",
        "league_history_leaderboard": "/auth/league-leaderboard-history/",
        "leave_league": "/auth/leave-league/",
        "kick_player": "/auth/kick-player/",

        // News endpoints
        "news_feed": "/sponsor/newsfeed/",

        // Specific Screen endpoints
        "home": "/api/website/home/",
        "event_config": "/api/website/event-config/",
        "leagues_screen_load": "/auth/leagues-screen/",
        "bracket_load": "/api/website/bracket/",
        "track_competition": "/api/website/track-competition/",

        //admin endpoints
        "user_statistics": "/auth/user-statistics/",
        "bracket_statistics": "/auth/bracket-statistics/",
        "admin_load": "/auth/admin-load/",

        // Ad endpoints
        "current_ad": "/sponsor/advertisement/",
        "count_click": "/sponsor/click-count/",
    }

}