import Layout from "../utilities/Layout";
import {ChangeEvent, useEffect, useState} from "react";
import {Event} from "../data/models/bracket_api/Event";
import Grid from "@mui/material/Grid";
import {
    Alert,
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogContentText, DialogTitle,
    InputAdornment, Snackbar,
    Theme,
    useMediaQuery
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import EventRow from "../components/admin/event_list/EventRow";
import {uuidv4} from "../utilities/UUIDGeneration";
import {get_events, EventResponse, delete_event} from "../apis/Event";
import * as React from "react";
import EventRowSkeleton from "../components/admin/event_list/EventRowSkeleton";
import Typography from "@mui/material/Typography";
import {Helmet} from "react-helmet";


export default function Events() {
    if (!JSON.parse(localStorage.getItem('user') as string).is_staff) {
        window.location.href = '/';
    }

    const [open, setOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState<Event | null>(null);
    const [eventName, setEventName] = useState<String>("");
    const [open2, setOpen2] = useState<boolean>(false);
    const [message, setMessage] = useState<String>("");
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("error");
    const [loading, setLoading] = useState<boolean>(true);
    const [events, setEvents] = useState<Event[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    const handleClickOpen = (event: Event) => {
        setOpen(true);
        setEventToDelete(event)
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(loading){
            get_events().then((response: EventResponse) => {
                if (response.succeeded) {
                    let e: Event[] = response.data;
                    e.sort((a: Event, b: Event) => {
                        return a.open_date > b.open_date ? -1 : 1;
                    });
                    setEvents(e);
                    setFilteredEvents(e);
                }
                setLoading(false);
            });
        }
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [events, isMdOrUp, loading]);

    function deleteEvent(event: Event) {
        delete_event(event.id as string).then((response: EventResponse) => {
            if(response.succeeded){
                setEvents(events.filter((e: Event) => e.id !== event.id));
                setFilteredEvents(filteredEvents.filter((e: Event) => e.id !== event.id));
                setMessage(response.message);
                setSeverity("success");
                setOpen2(true);
            }
            else{
                setMessage(response.message);
                setSeverity("error");
                setOpen2(true);
            }
            setEventToDelete(null)
            setEventName("")
        });
    }

    function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
        setFilteredEvents(events.filter((event2: Event) => event2.name.toLowerCase().includes(event.target.value.toLowerCase())));
    }

    return (
        <Layout>
            <Helmet>
                DRBB | Events
            </Helmet>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open2} onClose={() => {
                setOpen2(false)
            }}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To delete this event, please enter the name of the event below and hit delete.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Event Name"
                        fullWidth
                        variant="standard"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setEventName(event.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={eventName !== eventToDelete?.name} onClick={() => {
                        if(eventToDelete !== null) {
                            deleteEvent(eventToDelete)
                        }
                        handleClose()
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Grid container sx={{
                display: 'flex',
                flexDirection: isMdOrUp ? 'row' : 'column-reverse',
                height: 'fit-content',
                minHeight: 'calc(100vh - 384px)',
                justifyContent: 'center',
                p: 2,
                m: 2,
                width: '100%'
            }}>
                <Grid container sx={{
                    flex: .75,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'fit-content',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <TextField
                        label="Search Race Name"
                        variant="filled"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            )
                        }}
                        sx={{
                            backgroundColor: 'background.paper',
                            borderRadius: '5px',
                            width: '40vw',
                            minWidth: '300px',
                        }}
                        onChange={handleSearchChange}
                    />
                    { loading ? <>
                            <EventRowSkeleton />
                            <EventRowSkeleton />
                            <EventRowSkeleton />
                        </> :
                        filteredEvents.map((event: Event) => {
                            return <EventRow key={event.id} event={event} deleteFunction={handleClickOpen}/>
                        })
                    }
                </Grid>
                <Grid container sx={{
                    flex: .25,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'fit-content',
                    alignItems: 'center',
                }}>
                    <Button variant={"contained"} sx={{
                        margin: 1,
                        borderRadius: '0',
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}
                            onClick={() => {window.location.href = '/admin/events/' + uuidv4()}}
                    >
                        <Typography variant={'h6'}>
                            Create Event
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    )
}