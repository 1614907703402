import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Leaderboard, {LeaderboardProps} from "./Leaderboard";
import {SxProps, Theme, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import "../../styles/leaderboard_carousel.css";
import {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";

type LeaderboardCarouselProps = {
    league_name: string,
    leaderboards: LeaderboardProps[],
    sx?: SxProps,
    league_id: string,
    event_id: string,
}

export default function LeaderboardCarousel(props: Readonly<LeaderboardCarouselProps>) {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    return (
        <Grid container component={Paper} elevation={3} width={'unset'} position={"relative"} sx={props.sx}>
            <Carousel
                className={"leaderboard-carousel"}
                showIndicators={props.leaderboards.length > 1}
                swipeable={true}
                showArrows={false}
                showStatus={false}
                infiniteLoop={false}
                showThumbs={false}
                dynamicHeight={false}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
            >
                {props.leaderboards.map((leaderboard: LeaderboardProps) => {
                    return (
                        <Leaderboard key={leaderboard.display_name} display_name={leaderboard.display_name}
                                     entries={leaderboard.entries} viewable={leaderboard.viewable}
                                        event_id={props.event_id} league_id={props.league_id} 
                        />
                    )
                })}
            </Carousel>
        </Grid>
    )
}