import Layout from "../utilities/Layout";
import React, {useEffect, useState} from "react";
import {Event} from "../data/models/bracket_api/Event"
import {get_events_by_season} from "../apis/Event";
import Grid from "@mui/material/Grid";
import Loader from "../components/common/Loader";
import Typography from "@mui/material/Typography";
import {
    Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem,
    TimelineOppositeContent, TimelineSeparator
} from "@mui/lab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import {Divider, FormControl, InputLabel, MenuItem, Select, Stack, Theme, useMediaQuery} from "@mui/material";
import {Helmet} from "react-helmet";

export default function RaceList() {
    const [events, setEvents] = useState<Event[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [selectedYear, setSelectedYear] = useState<number>(2025);

    useEffect(() => {
        if (loading) {
            get_events_by_season(selectedYear).then((response) => {
                if (response.succeeded) {
                    setEvents(response.data)
                } else {
                    console.log(response.message)
                }
            });
        }
        setLoading(false)
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [loading, isMdOrUp]);

    const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {

    };

    const determineAlignment = (index: number) => {
        if (isMdOrUp) {
            return index % 2 === 0 ? 'flex-end' : 'flex-start'
        } else {
            return 'center'
        }
    }

    return (
        <Layout>
            <Grid container display={"flex"} flexDirection={"column"}>
                <Helmet>
                    <title>DRBB | Races</title>
                </Helmet>
                <FormControl variant="outlined" style={{ margin: '10px' , minWidth: 200, alignSelf: 'flex-end'}}>
                    <InputLabel id="year-select-label">Season Year</InputLabel>
                    <Select
                        labelId="year-select-label"
                        id="year-select"
                        value={selectedYear}
                        onChange={(event) => {
                            setSelectedYear(event.target.value as number);
                            setLoading(true);
                        }}
                        label="Season Year"
                    >
                        <MenuItem value={2025}>2025</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                    </Select>
                </FormControl>
                {
                    loading ?
                        <Loader/>
                        :
                        <Timeline position="alternate-reverse">
                            {events.map((event: Event, index: number) => {
                                return (
                                    <TimelineItem>
                                        <TimelineOppositeContent
                                            sx={{
                                                m: 'auto 0',
                                            }}
                                            align="right"
                                        >
                                            <Typography variant={isMdOrUp ? "h6" : "body1"} component="span" sx={{
                                                backgroundColor: 'background.paper',
                                                borderRadius: '10px',
                                                p: 1
                                            }}>
                                                {new Date(event.start_date).toLocaleDateString(undefined, {
                                                    month: 'long',
                                                    day: 'numeric'
                                                })}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            {index !== 0 ? <TimelineConnector/> : <TimelineConnector style={{visibility: 'hidden'}}/>}
                                            <TimelineDot
                                                color={event.is_active ? "success" : new Date() > new Date(event.end_date) ? "error" : "info"}/>
                                            {index !== events.length - 1 && <TimelineConnector/>}
                                        </TimelineSeparator>
                                        <TimelineContent
                                            sx={{
                                                py: '12px',
                                                px: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                justifyContent: determineAlignment(index),
                                            }}
                                            onClick={() => window.location.href = '/race/detail?' + encodeURIComponent('event_id=' + event.id)}
                                        >
                                            <Stack direction={isMdOrUp ? 'row' : 'column'} sx={{
                                                backgroundColor: 'background.paper',
                                                borderRadius: '10px',
                                                justifyContent: 'center',
                                                p: 1,
                                                alignItems:'center'
                                            }}>
                                                {
                                                    event.image_link !== null &&
                                                    <img src={event.image_link} alt={event.name}
                                                         style={{maxWidth: 'min(300px,31vw)'}}/>
                                                }
                                                <Stack direction={"column"} textAlign={"left"} p={1}>
                                                    <Typography variant={isMdOrUp ? "h6" : "body1"}
                                                                fontWeight={isMdOrUp ? "bold" : "normal"}
                                                                fontSize={isMdOrUp ? "1.5rem" : "0.9rem"}
                                                                component="span">
                                                        {event.name}
                                                    </Typography>
                                                    <Divider/>
                                                    <Typography variant="body2" component="span">
                                                        {event.series !== undefined && event.series.name !== "2024 Full Season" ? event.series.name : ""}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </TimelineContent>
                                    </TimelineItem>
                                )
                            })}
                        </Timeline>
                }
            </Grid>
        </Layout>
    )

}