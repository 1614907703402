import React, { useState, useEffect } from 'react';
import { Grid, Paper, SxProps, Theme, useMediaQuery } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TrackLeaderboard, { TrackLeaderboardProps } from "./TrackLeaderboard";
import "../../styles/leaderboard_carousel.css";

type TrackLeaderboardCarouselProps = {
    league_name: string,
    leaderboards: TrackLeaderboardProps[],
    setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
    setSnackbarSeverity: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
    sx?: SxProps,
}

export default function TrackLeaderboardCarousel(props: Readonly<TrackLeaderboardCarouselProps>) {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    return (
        <Grid container component={Paper} elevation={3} width={'unset'} position={"relative"} sx={props.sx}>
            <Carousel
                className={"leaderboard-carousel"}
                showIndicators={props.leaderboards.length > 1}
                swipeable={true}
                showArrows={false}
                showStatus={false}
                infiniteLoop={false}
                showThumbs={false}
                dynamicHeight={false}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
            >
                {props.leaderboards.map((leaderboard: TrackLeaderboardProps) => {
                    return (
                        <TrackLeaderboard
                            key={leaderboard.display_name}
                            display_name={leaderboard.display_name}
                            entries={leaderboard.entries}
                            viewable={leaderboard.viewable}
                            user_id={leaderboard.user_id}
                            setSnackbarOpen={props.setSnackbarOpen}
                            setSnackbarMessage={props.setSnackbarMessage}
                            setSnackbarSeverity={props.setSnackbarSeverity}
                        />
                    )
                })}
            </Carousel>
        </Grid>
    )
}