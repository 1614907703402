import {Config} from "../Config";
import {UUID} from "crypto";
import {AuthResponse, refresh_token} from "./Auth";
import {Qualifier} from "../data/models/bracket_api/Qualifier";
import {FourWideResult} from "../data/models/bracket_api/FourWideResult";
import {Bracket} from "../data/models/bracket_api/Bracket";
import {Result} from "../data/models/bracket_api/Result";
import {Event} from "../data/models/bracket_api/Event";

const config: Config = new Config();

export async function home_load() {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let user_id: number
        try {
            user_id = JSON.parse(localStorage.getItem('user') as string).id;
        } catch (e) {
            user_id = 0;
        }
        const response = await fetch(config.backendURL + config.endpoints.home + user_id + "/", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        let data = await response.json();
        switch (response.status) {
            case 200:
                return {
                    succeeded: true,
                    data: data,
                    message: "Successfully loaded home page"
                };
            case 404:
                return {
                    succeeded: false,
                    data: null,
                    message: "Not found"
                };
            case 500:
                return {
                    succeeded: false,
                    data: null,
                    message: "Internal server error"
                };
            default:
                return {
                    succeeded: false,
                    data: null,
                    message: "Unknown error"
                };
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export type EventConfigLoadResponse = {
    succeeded: boolean,
    data: any | null,
    message: string,
}

export async function event_config_load(id: UUID): Promise<EventConfigLoadResponse> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
    try {
        try {
            if (!JSON.parse(localStorage.getItem('user') as string).is_staff) {
                window.location.href = '/';
            }
        } catch (e) {
            window.location.href = '/';
        }
        const response = await fetch(config.backendURL + config.endpoints.event_config + id + "/", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
            },
        });
        let data = await response.json();
        if (response.status < 400) {
            return {succeeded: true, data: data, message: "Successfully loaded event_config page"};
        }
        else if(response.status === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await event_config_load(id);
            } else {
                return {succeeded: false, message: "Access token is invalid", data: data};
            }
        }
        else{
            return {succeeded: false, message: "Error loading event config page", data: data};
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}

type EventConfigPostRequest = {
    event: Event,
    qualifiers?: Qualifier[],
    brackets?: Bracket[],
    results?: Result[],
    four_wide_results?: FourWideResult[],
}
export async function event_config_post(props: EventConfigPostRequest): Promise<EventConfigLoadResponse>  {
    if (window.location.pathname === "/error") {
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
    try {
        try {
            if (!JSON.parse(localStorage.getItem('user') as string).is_staff) {
                window.location.href = '/';
            }
        } catch (e) {
            window.location.href = '/';
        }
        const id = props.event.id;
        const response = await fetch(config.backendURL + config.endpoints.event_config + id + "/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
            },
            body: JSON.stringify(props),
        });
        let data = await response.json();
        if (response.status < 400) {
            return {succeeded: true, data: data, message: "Successfully saved event"};
        }
        else if(response.status === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await event_config_post(props);
            } else {
                return {succeeded: false, message: "Access token is invalid", data: data};
            }
        }
        else{
            return {succeeded: false, message: "Error loading event config page", data: data};
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}

type BracketScreenLoadResponse = {
    succeeded: boolean,
    data: any | null,
    message: string,
}
export async function bracket_screen_load(league_id: string, user_id: string, event_id: string): Promise<BracketScreenLoadResponse> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
    if(league_id === "" || league_id === undefined || league_id === null || league_id === "undefined" || league_id === "null"){
        league_id = "1"
    }
    if(event_id === "" || event_id === undefined || event_id === null || event_id === "undefined" || event_id === "null"){
        event_id = "0"
    }
    user_id = encodeURIComponent(user_id)
    try {
        const response = await fetch(config.backendURL + config.endpoints.bracket_load + event_id + `/${league_id}/${user_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
            },
        });
        let data = await response.json();
        if (response.status < 400) {
            return {succeeded: true, data: data, message: "Successfully saved event"};
        }
        else if(response.status === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await bracket_screen_load(league_id, user_id, event_id);
            } else {
                return {succeeded: false, message: "Access token is invalid", data: data};
            }
        }
        else{
            return {succeeded: false, message: "Error loading event config page", data: data};
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}

export type TrackCompetitionResponse = {
    succeeded: boolean;
    statusCode: number;
    message: String;
    data?: any;
}

export async function track_competition_load(): Promise<TrackCompetitionResponse> {
    if(window.location.pathname === "/error"){
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try{
        let response: Response = await fetch(config.backendURL + config.endpoints.track_competition, {
            method: 'GET',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
            },
        });
        let responseStatus: number = response.status;
        let data: any = await response.json();
        switch (responseStatus) {
            case 200:
                return {
                    succeeded: true, statusCode: 200, message: "Tracks retrieved successfully", data: data,
                };
            case 400:
                return {succeeded: false, statusCode: 400, message: "Tracks failed to retrieve"};
            case 401:
                let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
                if (refresh_validation.succeeded) {
                    return await track_competition_load();
                } else {
                    return {succeeded: false, statusCode: 401, message: data["detail"]};
                }
            case 404:
                return {succeeded: false, statusCode: 404, message: "Servers are down, please come back later"};
            case 500:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
            default:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
        }
    }
    catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}