import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Advertisement} from "../../data/models/sponsor_api/Advertisement";
import {Config} from "../../Config";

const config = new Config();

type AdSpotProps = {
    id: string,
    sx?: any
}
export default function AdSpot(props: AdSpotProps) {
    const [ad, setAd] = useState<Advertisement | null>(null);

    useEffect(() => {
        const fetchAd = async () => {
            if(!ad) {
                let response: Response = await fetch(config.backendURL + config.endpoints.current_ad + props.id + '/', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json;charset=UTF-8',
                    },
                });
                let data = await response.json();
                let responseStatus: number = response.status;
                if (responseStatus < 400) {
                    setAd(data);
                } else {
                    console.error("Failed to fetch ad");
                }
            }
        }
        fetchAd();
    }, []);

    return (
        <>
            {
                ad !== null ?
                    <Box sx={props.sx} position={'relative'}>
                        <img src={ad?.image_link} alt={'ad'} width={'100%'} style={{cursor: "pointer"}} onClick={
                            () => {
                                fetch(config.backendURL + config.endpoints.count_click + props.id + '/', {
                                    method: 'POST',
                                    headers: {
                                        'content-type': 'application/json;charset=UTF-8',
                                    },
                                    body: JSON.stringify({
                                        user_id: JSON.parse(localStorage.getItem("user")!).id || 0,
                                    })
                                }).then(() => window.open(ad?.click_through_link, '_blank'))
                            }
                        }/>
                    </Box>
                    :
                    <></>
            }
        </>
    )
}