import Layout from "../utilities/Layout";
import Typography from "@mui/material/Typography";
import { User } from "../data/models/user_auth/User";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Alert, Paper, Skeleton, Snackbar } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import { PieChart } from "@mui/x-charts";
import { pickStats } from "../apis/Pick";
import { get_user_mail_settings, manage_subscribe } from "../apis/Mailchimp";
import DeleteConfirmationDialog from "../components/account/DeleteConfirmationDialog";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CreateIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";
import { League as league } from "../data/models/user_auth/League";
import * as LeagueAPI from "../apis/League";
import GA4EventSend from "../utilities/GA4EventSend";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material";
import header from "../assets/images/AccountHeaderGraphic.png";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

type PickStats = {
  correct: number;
  incorrect: number;
  pending: number;
};

export default function Account() {
  // Check to make sure user is logged in and we have there info, if not redirect to login
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [userPicks, setUserPicks] = useState<PickStats | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<String>("");
  const [severity, setSeverity] = useState<
    "success" | "info" | "warning" | "error" | undefined
  >("error");
  const [isMdOrUp, setIsMdOrUp] = useState(
    useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))
  );
  const [leagues, setLeagues] = useState<Array<league>>([]);
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (loading) {
      if (localStorage.getItem("user") !== null) {
        setUserInfo(JSON.parse(localStorage.getItem("user")!));
        LeagueAPI.leagues_screen_load(
          JSON.parse(localStorage.getItem("user")!).id as number
        ).then((response: LeagueAPI.LeagueResponse) => {
          if (response.succeeded) {
            let leagues = response.data["leagues"];
            leagues.sort((a: league, b: league) => {
              // Put "Drag Race Bracket Bonanza" first
              if (a.name === "Drag Race Bracket Bonanza") return -1;
              if (b.name === "Drag Race Bracket Bonanza") return 1;

              // Then sort the rest alphabetically
              return a.name.localeCompare(b.name);
            });
            setLeagues(leagues);
          } else {
            let pathname = encodeURIComponent("/account");
            window.location.href = "/signin/redirect?path=" + pathname;
          }
          GA4EventSend(
            "leagues",
            "load",
            "leagues",
            response.statusCode,
            false
          );
        });
      } else {
        let pathname = encodeURIComponent("/account");
        window.location.href = "/signin/redirect?path=" + pathname;
      }
      try {
        pickStats(
          localStorage.getItem("access_token")!,
          localStorage.getItem("refresh")!
        ).then((response) => {
          if (response.succeeded) {
            setUserPicks(response.data);
          }
        });
      } catch (e) {}
    }
    setLoading(false);
    const handleResize = () => {
      setIsMdOrUp(window.innerWidth >= 960);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [loading, isMdOrUp]);

  return (
    <Layout>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          elevation={6}
          severity={severity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>

      <Paper
        sx={{
          width: "85%",
          m: "auto",
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 0,
          borderRadius: "10px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        elevation={3}
      >
        <Grid minWidth="100%" m="auto">
          <img
            src={header}
            alt="header"
            style={{
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              height: "fit-content",
              width: isMdOrUp ? "100%" : "",
            }}
          />
        </Grid>
        <Grid
          container
          direction={isMdOrUp ? "row" : "column"}
          display={"flex"}
          flexWrap="nowrap"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Grid ml={1}>
            {userInfo ? (
              <Grid container direction={"column"} flex={0.5}>
                <Grid display="flex" flexDirection="row" alignItems="center">
                  <Typography
                    variant={"h4"}
                    fontWeight={"bolder"}
                    margin={"8px"}
                  >
                    {userInfo.nickname}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      window.location.href = "/change-nickname";
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                </Grid>
                <Divider sx={{ ml: -1 }} />
                <Grid display="flex" flexDirection="row" alignItems="center">
                  <Typography
                    variant={"h6"}
                    fontWeight={"bolder"}
                    margin={"8px"}
                  >
                    {userInfo.first_name + " " + userInfo.last_name}
                  </Typography>
                </Grid>
                <Grid display="flex" flexDirection="row" alignItems="center">
                  <Typography
                    variant={"h6"}
                    fontWeight={"bolder"}
                    margin={"8px"}
                  >
                    {userInfo.email}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      window.location.href = "/change-email";
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                </Grid>
                {/*<Grid display="flex" flexDirection="row" alignItems="center">*/}
                {/*    <Typography variant={"h6"} fontWeight={"bolder"} margin={"8px"}>*/}
                {/*        {userInfo.phone_number ? userInfo.phone_number : 'No Phone Number on Record'}*/}
                {/*    </Typography>*/}
                {/*    <IconButton onClick={() => {*/}
                {/*        window.location.href = "/change-phone"*/}
                {/*    }}>*/}
                {/*        <CreateIcon/>*/}
                {/*    </IconButton>*/}
                {/*</Grid>*/}

                <Button
                  variant={"contained"}
                  sx={{
                    margin: "8px",
                    backgroundColor: "#486ce7",
                    color: "#ffffff",
                    borderRadius: 0,
                    ":hover": {
                      backgroundColor: "#c40000",
                      color: "#ffffff",
                    },
                  }}
                  disabled={isClicked}
                  onClick={() => {
                    window.open(
                      "https://mailchi.mp/2a9d16e6744b/sms-signup",
                      "_blank"
                    );
                  }}
                >
                  Sign Up For SMS Notifications
                </Button>

                <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{
                    margin: "8px",
                    backgroundColor: "#486ce7",
                    color: "#ffffff",
                    borderRadius: 0,
                    ":hover": {
                      backgroundColor: "#c40000",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => {
                    window.location.href = "/change-password";
                  }}
                >
                  Change Password
                </Button>
              </Grid>
            ) : (
              <Grid container direction={"column"} flex={0.5}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            )}
          </Grid>
          {!isMdOrUp && (
            <Grid
              display="flex"
              flexDirection="column"
              textAlign={isMdOrUp ? "left" : "center"}
              ml={2}
            >
              <Typography variant="h4" fontWeight="bold">
                Pick Stats
              </Typography>
              <Typography sx={{ fontWeight: "lighter" }}>
                Press for more detail
              </Typography>
            </Grid>
          )}
          {userPicks ? (
            userPicks.correct + userPicks.incorrect + userPicks.pending ===
            0 ? (
              <PieChart
                colors={["gray"]}
                series={[
                  {
                    innerRadius: 40,
                    data: [{ id: 0, value: 1, label: "No Picks Made" }],
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                height={isMdOrUp ? 250 : 160}
              />
            ) : (
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: isMdOrUp ? "50%" : "100%",
                }}
              >
                <PieChart
                  colors={["blue", "red", "gray"]}
                  series={[
                    {
                      innerRadius: isMdOrUp ? 40 : 0,
                      data: [
                        {
                          id: 0,
                          value: userPicks.correct,
                          label: isMdOrUp ? "Correct Picks" : "Correct",
                        },
                        {
                          id: 1,
                          value: userPicks.incorrect,
                          label: isMdOrUp ? "Incorrect Picks" : "Incorrect",
                        },
                        {
                          id: 2,
                          value: userPicks.pending,
                          label: isMdOrUp ? "Pending Picks" : "Pending",
                        },
                      ],
                      highlightScope: { faded: "global", highlighted: "item" },
                      faded: {
                        innerRadius: 30,
                        additionalRadius: -30,
                        color: "gray",
                      },
                    },
                  ]}
                  height={isMdOrUp ? 250 : 160}
                />
              </Grid>
            )
          ) : (
            <Grid width="50%">
              <PieChart
                colors={["gray"]}
                series={[
                  {
                    innerRadius: 40,
                    data: [{ id: 0, value: 1, label: "No Picks Made" }],
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                height={isMdOrUp ? 250 : 160}
                sx={{ display: "flex", flexWrap: "nowrap", width: "50%" }}
              />
            </Grid>
          )}
        </Grid>

        <Typography
          variant="h4"
          ml={3}
          mt={4}
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "left",
            width: "100%",
          }}
        >
          Leagues
        </Typography>
        <Grid
          display="flex"
          flexDirection="row"
          width="100%"
          sx={{ overflowX: "auto" }}
        >
          {leagues.map((league: league) => (
            <Card
              component={Paper}
              elevation={3}
              sx={{
                minWidth: "300px",
                minHeight: "200px",
                m: 3,
                cursor: "pointer",
                background: darkMode ? "rgba( 255, 255, 255, 0.25 )" : "",
                boxShadow: darkMode
                  ? "0 8px 32px 0 rgba( 31, 38, 135, 0.37 "
                  : "",
                backdropFilter: darkMode ? "blur( 13.5px )" : "",
                borderRadius: "10px",
                border: darkMode ? "1px solid rgba( 255, 255, 255, 0.18 )" : "",
              }}
              onClick={() => {
                window.location.href =
                  "/league/?" + encodeURIComponent("league_id=" + league.id);
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "5px !important",
                }}
              >
                <Grid>
                  {league.image_url ? (
                    <Avatar
                      src={league.image_url}
                      sx={{ width: "150px", height: "150px" }}
                    ></Avatar>
                  ) : (
                    <Avatar sx={{ width: "150px", height: "150px" }} />
                  )}
                </Grid>
                <Typography
                  variant="h5"
                  display="flex"
                  alignItems="flex-end"
                  sx={{ textAlign: "center", height: "100%" }}
                >
                  {league.name}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>

        <Grid
          container
          direction={"row"}
          sx={{
            justifyContent: "space-evenly",
            mt: 3,
            mb: 3,
          }}
        >
          <DeleteConfirmationDialog />
        </Grid>
      </Paper>
    </Layout>
  );
}
