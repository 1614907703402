import React from 'react';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import SignIn from "./pages/SignIn";
import {ThemeProvider} from '@emotion/react';
import {createTheme} from "@mui/material/styles";
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import {useMediaQuery} from "@mui/material";
import BracketScreen from "./pages/Bracket";
import Leagues from "./pages/Leagues";
import LeagueCreate from "./pages/LeagueCreate";
import LeagueDetail from "./pages/LeagueDetail";
import Admin from "./pages/Admin";
import Intro from "./pages/Intro";
import LeagueHistory from "./pages/LeagueHistory";
import Events from "./pages/Events";
import EventConfig from "./pages/EventConfig";
import Invite from "./pages/Invite";
import ErrorScreen from "./pages/ErrorScreen";
import Terms from "./pages/Terms";
import Account from "./pages/Account";
import Privacy from "./pages/Privacy";
import ResetPassword from './pages/ResetPassword';
import LeagueJoin from "./pages/LeagueJoin";
import ChangePassword from "./pages/ChangePassword";
import ChangeEmail from "./pages/ChangeEmail";
import ChangePhone from "./pages/ChangePhone";
import ChangeNickname from "./pages/ChangeNickname";
import {DriverResponse, get_drivers} from "./apis/Driver";
import LeagueEdit from "./pages/LeagueEdit";
import ReactGA from "react-ga4";
import GA4EventSend from "./utilities/GA4EventSend";
import About from "./pages/About";
import Help from "./pages/Help";
import Stats from './pages/DriverStats';
import IndividualStats from './pages/IndividualStats';
import RaceList from "./pages/RaceList";
import RaceDetail from "./pages/RaceDetail";
import TrackCompetition from './pages/TrackCompetition';

function App() {
    ReactGA.initialize('G-H4YJ1PDNTD');

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    // const prefersDarkMode = false;

    const fontSizes = {
        xs: 12,
        sm: 14,
        md: 16,
        lg: 18,
        xl: 20,
    }

    let theme = createTheme({
        palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
            primary: {
                main: '#486ce7'
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        typography: {
            fontSize: fontSizes.sm,
        },
    });

    setTimeout(() => {
        get_drivers().then((response: DriverResponse) => {
            if(response.succeeded) {
                GA4EventSend('drivers', 'load', 'drivers', response.statusCode, true)
                localStorage.setItem('drivers', JSON.stringify(response.data));
            }
            else{
                GA4EventSend('drivers', 'load', 'drivers', response.statusCode, false)
            }
        });
    }, 30000);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>

                    <Route path="/about" element={<About/>}/>
                    <Route path="/aboutus" element={<About/>}/>

                    <Route path={"/help"} element={<Help/>}/>

                    <Route path="/error" element={<ErrorScreen/>}/>


                    <Route path="/leagues" element={<Leagues/>}/>
                    <Route path="/league/create/" element={<LeagueCreate/>}/>
                    <Route path="/league/edit/" element={<LeagueEdit/>}/>
                    <Route path="/join" element={<LeagueJoin/>}/>
                    <Route path="/league/" element={<LeagueDetail/>}/>
                    <Route path="/league/history/" element={<LeagueHistory/>}/>
                    <Route path="/bracket/" element={<BracketScreen/>}/>


                    <Route path="/admin" element={<Admin/>}/>
                    <Route path="/admin/events" element={<Events/>}/>
                    <Route path="/admin/events/:eventId" element={<EventConfig/>}/>

                    <Route path="/signin" element={<SignIn/>}/>
                    <Route path="/signin/redirect" element={<SignIn/>}/>
                    <Route path="/signup" element={<SignUp/>}/>
                    <Route path="/intro" element={<Intro/>}/>
                    <Route path="/forgot_password" element={<ForgotPassword/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/account" element={<Account/>}/>

                    <Route path="/reset-password-confirm/:resetToken" element={<ResetPassword/>}/>
                    <Route path="/invite" element={<Invite/>}/>

                    <Route path="/change-password" element={<ChangePassword/>}/>
                    <Route path="/change-email" element={<ChangeEmail/>}/>
                    <Route path="/change-phone" element={<ChangePhone/>}/>
                    <Route path="/change-nickname" element={<ChangeNickname/>}/>

                    <Route path='/stats' element={<Stats/>}/>
                    <Route path='/stats/detail' element={<IndividualStats/>}/>

                    <Route path='/races' element={<RaceList/>}/>
                    <Route path='/race/detail' element={<RaceDetail/>}/>

                    <Route path='/track-challenge' element={<TrackCompetition/>}/>

                    {/*<Route path={"/test"} element={<StatPortalLayout>test</StatPortalLayout>}/>*/}
                    {/*<Route path={"/analytics/individual"} element={<StatPortalIndidualDriverStats/>}/>*/}
                    {/*<Route path={"/analytics/pitcrew"} element={<StatPortalPitCrew/>}/>*/}
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;