import {Bracket} from "../../../../data/models/bracket_api/Bracket";
import {Result} from "../../../../data/models/bracket_api/Result";
import {Pick} from "../../../../data/models/bracket_api/Pick";
import {RaceClass} from "../../../../data/models/bracket_api/RaceClass";
import {Driver} from "../../../../data/models/bracket_api/Driver";
import TopFuel from "../../../../assets/images/bracket_crests/top_fuel.png";
import ProStock from "../../../../assets/images/bracket_crests/pro_stock.png";
import FunnyCar from "../../../../assets/images/bracket_crests/funny_car.png";
import ProStockMotorcycle from "../../../../assets/images/bracket_crests/pro_stock_motorcycle.png";
import Grid from "@mui/material/Grid";
import PrintBlock from "./PrintBlock";
import BracketConnector from "../PrintBracketConnector";
import {PickStatus} from "../../../../data/models/bracket_api/PickStatus";
import Typography from "@mui/material/Typography";
import PrintBlockReverse from "./PrintBlockReverse";
import BracketConnectorReverse from "../PrintBracketConnectorReverse";
import TextField from "@mui/material/TextField";
import {uuidv4} from "../../../../utilities/UUIDGeneration";
import {UUID} from "crypto";

export type TwoWidePickBracketProps = {
    race_class: RaceClass,
    bracket?: Bracket,
    results?: Result[],
    picks?: Pick[],
    updatePick: (pick: Pick) => void,
    picks_locked?: boolean
}
export default function PrintBracket({race_class, bracket, results, picks, updatePick, picks_locked}: Readonly<TwoWidePickBracketProps>){
    let winner: Driver | undefined = picks?.find(pick => {
        return pick.result.round === 4 && pick.winner
    })?.winner;

    const determineCrestImage = () => {
        switch (race_class) {
            case RaceClass.TOP_FUEL:
                return TopFuel;
            case RaceClass.PRO_STOCK:
                return ProStock;
            case RaceClass.FUNNY_CAR:
                return FunnyCar;
            case RaceClass.PRO_STOCK_MOTORCYCLE:
                return ProStockMotorcycle;
            default:
                return TopFuel;
        }
    }

    const crestImage = determineCrestImage();
    return(
        race_class === RaceClass.TOP_FUEL || race_class === RaceClass.PRO_STOCK ?
            <Grid container sx={{
                display: 'flex',
                flexDirection: "row",
                position: "relative",
                minWidth: 'fit-content',
                flexWrap: "nowrap",
            }}>
                <Grid container sx={{
                    flex: .24,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 3
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 3
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 4
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 4
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 5
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 5
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 6
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 6
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 7
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 7
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 8
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 8
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .02,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnector height={"12.75%"} fourWide={false}/>
                    <BracketConnector height={"12.75%"} fourWide={false}/>
                    <BracketConnector height={"12.75%"} fourWide={false}/>
                    <BracketConnector height={"12.75%"} fourWide={false}/>
                </Grid>
                <Grid container sx={{
                    flex: .24,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',

                }}>
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 3
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 3
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 4
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 4
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .02,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center"
                }}>
                    <BracketConnector height={"25.5%"} fourWide={false}/>
                    <BracketConnector height={"25.5%"} fourWide={false}/>
                </Grid>
                <Grid container sx={{
                    flex: .24,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center"
                }}>
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 3 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <img src={crestImage} alt={"Crest"} width={'120px'} style={{marginTop: "7px", marginBottom: "7px"}}/>
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 3 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .02,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>
                    <BracketConnector height={"51%"} fourWide={false}/>
                </Grid>
                <Grid container sx={{
                    flex: .24,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                }}>
                    <PrintBlock
                        pick={picks?.find((pick) => {
                            return pick.result.round === 4 && pick.result.game === 1 && pick.pick_status !== PickStatus.TIEBREAKER
                        })}
                        result={results?.find((result) => {
                            return result.round === 4 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <Typography variant="body2" component="div" textAlign={"center"} position={"absolute"} top={"56%"}
                                width={"100%"} color={"#000000"}
                    >
                        {winner ? `Winner: ${winner.first_name + " " + winner.last_name}` : "Winner: ___________"}
                    </Typography>
                    <Typography variant="body2" component="div" textAlign={"center"} position={"absolute"} top={"33%"}
                                width={"100%"} color={"#000000"}
                    >
                        {picks?.find((pick) => pick.result.round === 4 && pick.result.game === 1 && pick.pick_status === PickStatus.TIEBREAKER)
                            ? `Top Speed: ${picks.find((pick) => pick.result.round === 4 && pick.result.game === 1 && pick.pick_status === PickStatus.TIEBREAKER)?.tiebreaker || "___________"}`
                            : "Top Speed: ___________"}
                    </Typography>
                </Grid>
            </Grid>
            :
            <Grid container sx={{
                display: 'flex',
                flexDirection: "row",
                position: "relative",
                minWidth: 'fit-content',
                flexWrap: "nowrap",
            }}>
                <Grid container sx={{
                    flex: .24,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                }}>
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 4 && pick.result.game === 1 && pick.pick_status !== PickStatus.TIEBREAKER
                        })}
                        result={results?.find((result) => {
                            return result.round === 4 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <Typography variant="body2" component="div" textAlign={"center"} position={"absolute"} top={"56%"}
                                width={"100%"} color={"#000000"}
                    >
                        {winner ? `Winner: ${winner.first_name + " " + winner.last_name}` : "Winner: ___________"}
                    </Typography>
                    <Typography variant="body2" component="div" textAlign={"center"} position={"absolute"} top={"33%"}
                                width={"100%"} color={"#000000"}
                    >
                        {picks?.find((pick) => pick.result.round === 4 && pick.result.game === 1 && pick.pick_status === PickStatus.TIEBREAKER)
                            ? `Top Speed: ${picks.find((pick) => pick.result.round === 4 && pick.result.game === 1 && pick.pick_status === PickStatus.TIEBREAKER)?.tiebreaker || "___________"}`
                            : "Top Speed: ___________"}
                    </Typography>
                </Grid>
                <Grid container sx={{
                    flex: .02,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>
                    <BracketConnectorReverse height={"51%"} fourWide={false}/>
                </Grid>
                <Grid container sx={{
                    flex: .24,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center"
                }}>
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 3 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <img src={crestImage} alt={"Crest"} width={'120px'} style={{marginTop: "12px", marginBottom: "12px"}}/>
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 3 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .02,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center"
                }}>
                    <BracketConnectorReverse height={"25.5%"} fourWide={false}/>
                    <BracketConnectorReverse height={"25.5%"} fourWide={false}/>
                </Grid>
                <Grid container sx={{
                    flex: .24,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 3
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 3
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 4
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 4
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .02,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnectorReverse height={"12.75%"} fourWide={false}/>
                    <BracketConnectorReverse height={"12.75%"} fourWide={false}/>
                    <BracketConnectorReverse height={"12.75%"} fourWide={false}/>
                    <BracketConnectorReverse height={"12.75%"} fourWide={false}/>
                </Grid>
                <Grid container sx={{
                    flex: .24,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 3
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 3
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 4
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 4
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 5
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 5
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 6
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 6
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 7
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 7
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PrintBlockReverse
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 8
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 8
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
            </Grid>

    )
}