import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  Link,
  Box,
  Button,
  Modal,
  TablePagination,
} from "@mui/material";
import { useMediaQuery, Theme } from "@mui/material";
import { join_league } from "../../apis/League";

export type TrackLeaderboardEntry = {
  rank: number;
  nickname: string;
  points: number;
  possible_points: number;
  leagueInviteCode: string;
};

export type TrackLeaderboardProps = {
  display_name: string;
  entries: TrackLeaderboardEntry[];
  viewable: boolean;
  user_id: number;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarSeverity: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
};

const TrackLeaderboard: React.FC<TrackLeaderboardProps> = ({
  entries,
  display_name,
  viewable,
  user_id,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity
}) => {
  const [isMdOrUp, setIsMdOrUp] = useState(
    useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    200
  );
  const [open, setOpen] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState<string | null>(null);
  const [selectedInviteCode, setSelectedInviteCode] = useState<string | null>(
    null
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpen = (leagueName: string, inviteCode: string) => {
    setSelectedLeague(leagueName);
    setSelectedInviteCode(inviteCode);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedLeague(null);
    setSelectedInviteCode(null);
  };

  const joinLeague = async () => {
    if (selectedInviteCode) {
      try {
        const response = await join_league(user_id, selectedInviteCode);
        if (response.statusCode === 200) {
          setSnackbarMessage("Successfully joined the league!");
          setSnackbarSeverity("success");
        } else if (response.statusCode === 400) {
          setSnackbarMessage("You are already in this league.");
          setSnackbarSeverity("error");
        }
      } catch (error) {
        setSnackbarMessage("Failed to join the league.");
        setSnackbarSeverity("error");
      } finally {
        setSnackbarOpen(true);
      }
    }
    handleClose();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMdOrUp(window.innerWidth >= 960);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMdOrUp]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: isMdOrUp ? "100%" : "80vh", borderRadius: isMdOrUp ? 'inherit' : '0px' }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#fff",
              }}
            >
              <TableCell colSpan={4}>
                <Typography variant={"h4"} fontWeight={"bold"} align={"center"}>
                  {display_name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ paddingX: 0.9 }}>
                <Typography
                  variant={"h6"}
                  fontWeight={"bold"}
                  sx={{ width: "10%" }}
                >
                  Rank
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ paddingX: 0.9 }}>
                <Typography variant={"h6"} fontWeight={"bold"}>
                  Track
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ paddingX: 0.9 }}>
                <Typography variant={"h6"} fontWeight={"bold"}>
                  Points
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ paddingX: 0.9 }}>
                <Typography variant={"h6"} fontWeight={"bold"}>
                  Possible Points
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.nickname}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ width: "10%", p: 0.8 }}
                  >
                    <Typography variant={"body1"}>{row.rank}</Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ p: 0.8 }}>
                    {viewable ? (
                      <Tooltip
                        title={"Join " + row.nickname + "'s League"}
                        placement={"top-start"}
                      >
                        <Link
                          href="#"
                          onClick={() =>
                            handleOpen(row.nickname, row.leagueInviteCode)
                          }
                        >
                          <Typography variant={"body2"} fontWeight={"600"}>
                            {row.nickname}
                          </Typography>
                        </Link>
                      </Tooltip>
                    ) : (
                      <Typography variant={"body2"} fontWeight={"600"}>
                        {row.nickname}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ p: 0.8 }}>
                    <Typography variant={"body2"}>{row.points}</Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ p: 0.8 }}>
                    <Typography variant={"body2"}>
                      {row.possible_points}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            <TableRow><TableCell sx={{borderBottom:'none'}}></TableCell></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {entries.length > 100 && (
        <TablePagination
          rowsPerPageOptions={isMdOrUp ? [10, 50, 100] : [10]}
          component="div"
          count={entries.length - 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure you want to join this league?
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {selectedLeague}
          </Typography>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained" color="primary" onClick={joinLeague}>
              Yes
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TrackLeaderboard;
