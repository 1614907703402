import Layout from "../utilities/Layout";
import {League} from "../data/models/user_auth/League";
import {useEffect, useState} from "react";
import ValidateSignIn from "../utilities/ValidateSignIn";
import * as LeagueAPI from "../apis/League";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
    Alert,
    FormControl,
    InputLabel,
    MenuItem,
    Modal, Select,
    Snackbar,
    Stack,
    Theme,
    useMediaQuery
} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import Box from "@mui/material/Box";
import CopyToClipboardButton from "../components/common/CopyToClipboardButton";
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {LeaderboardEntry, LeaderboardProps} from "../components/leaderboard/Leaderboard";
import LeaderboardCarousel from "../components/leaderboard/LeaderboardCarousel";
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EditIcon from '@mui/icons-material/Edit';
import GA4EventSend from "../utilities/GA4EventSend";
import Loader from "../components/common/Loader";
import Paper from "@mui/material/Paper";
import {Helmet} from "react-helmet";
import AdSpot from "../components/common/AdSpot";

export default function LeagueDetail() {
    let searchParams = window.location.href.substring(window.location.href.indexOf("?") + 1);
    let decoded = decodeURIComponent(searchParams);
    const queryParams = new URLSearchParams(decoded);
    const leagueId = queryParams.get('league_id') as unknown as number;
    const [league, setLeague] = useState<League | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModal2, setOpenModal2] = useState<boolean>(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [leaderboards, setLeaderboards] = useState<LeaderboardProps[]>([]);
    const [bracketsOpen, setBracketsOpen] = useState<boolean>(false);
    const [eventActive, setEventActive] = useState<boolean>(false);
    let user_id: number = JSON.parse(localStorage.getItem('user') as string).id;
    const [event_id, setEventId] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<String>("");
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("error"); // ["success", "info", "warning", "error"
    let user_nickname = JSON.parse(localStorage.getItem('user') as string).nickname;
    const [isCreator, setIsCreator] = useState<boolean>(false);
    const [bracketsViewable, setBracketsViewable] = useState<boolean>(false);
    const [players, setPlayers] = useState<string[]>([]);

    const [showLeaveModal, setShowLeaveModal] = useState<boolean>(false);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    useEffect(() => {
        if (loading) {
            ValidateSignIn().then(() => {
                // Get user id from local storage and convert it to number
                let user_id: number = JSON.parse(localStorage.getItem('user') as string).id;
                LeagueAPI.get_league(leagueId).then((response: LeagueAPI.LeagueResponse) => {
                    if (response.succeeded) {
                        if (user_nickname === response.data.creator) {
                            setIsCreator(true);
                        }
                        let leaderboards: LeaderboardProps[] = [];
                        if (response.data.event_id && response.data.leaderboard) {
                            setBracketsOpen(response.data.brackets_open);
                            setEventActive(response.data.event_active);
                            setEventId(response.data.event_id);
                            Object.entries(response.data.leaderboard).forEach(([key, value]) => {
                                let leaderboard_rows: LeaderboardEntry[] = [];
                                for (const element of value as any) {
                                    leaderboard_rows.push({
                                        rank: element.rank,
                                        nickname: element.nickname,
                                        points: element.points,
                                        possible_points: element.possible_points,
                                    })
                                }
                                leaderboards.push({
                                    display_name: key,
                                    entries: leaderboard_rows,
                                    viewable: !response.data.brackets_open,
                                    event_id: response.data.event_id,
                                    league_id: leagueId.toString(),
                                });
                            });
                        }
                        setBracketsViewable(response.data['brackets_viewable'])
                        setLeaderboards(leaderboards);
                        if (response.data.players) {
                            setPlayers(response.data.players);
                        }
                        setLeague({
                            id: response.data.id,
                            name: response.data.name,
                            creator: response.data.creator,
                            invite_code: response.data.invite_code,
                            players: [],
                            image_url: response.data.image_url,
                        });
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                    GA4EventSend("league_detail", "load", "league_detail", response.statusCode, false);
                });
            });
        }
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    function invitePlayer(email: string) {
        if (email === "" || !email.includes("@") || !email.includes(".")) {
            setMessage("Invalid email address");
            setSeverity("error");
            setOpen(true);
            setIsClicked(false);
            return;
        }
        LeagueAPI.invite_player_to_league(email, user_id, leagueId).then((response: LeagueAPI.LeagueResponse) => {
            if (response.succeeded) {
                setMessage("Sent invite to " + email);
                setSeverity("success");
                setOpen(true);
            } else {
                setMessage("Failed to send invite to " + email);
                setSeverity("error");
                setOpen(true);
            }
            setIsClicked(false);
        });
    }

    const handleConfirm = () => {
        let replacementNickname = isCreator ? selectedNickname : null;
        LeagueAPI.leave_league(leagueId, user_id, replacementNickname).then(
            (response) => {
                if (response.succeeded) {
                    setMessage("Successfully left league.");
                    setSeverity("success");
                    setOpen(true)
                    setShowLeaveModal(false);
                    setTimeout(() => {
                        window.location.href = '/leagues'
                    }, 3000);
                } else {
                    setMessage("Failed to leave league.");
                    setSeverity("error");
                    setOpen(true);
                    setShowLeaveModal(false);
                }
            });
    };

    const handleCancel = () => {
        setShowLeaveModal(false);
    };

    const handleKickConfirm = () => {
        LeagueAPI.kick_player(leagueId, selectedNickname).then(
            (response) => {
                if (response.succeeded) {
                    setMessage("Removed " + selectedNickname + " from the league.");
                    setSeverity("success");
                    setOpen(true)
                    setShowRemoveModal(false);
                } else {
                    setMessage("Failed to remove " + selectedNickname + " from the league.");
                    setSeverity("error");
                    setOpen(true);
                    setShowRemoveModal(false);
                }
            });
    };

    const handleKickCancel = () => {
        setShowRemoveModal(false);
    };

    const [selectedNickname, setSelectedNickname] = useState<string>("");

    const filteredPlayers = players.filter(player => player !== user_nickname);

    return (
        <>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => setOpen(false)}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Helmet>
                <title>DRBB | League Detail</title>
            </Helmet>
            <Modal open={openModal} onClose={() => {
                setOpenModal(false)
            }} sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                background: 'transparent',
                boxShadow: 'shadow.5',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    padding: 2,
                    backgroundColor: 'background.paper',
                    height: '500px',
                    width: 'fit-content',
                    borderRadius: '5px',
                    position: 'relative',
                }}>
                    <CloseIcon sx={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        margin: '1rem',
                        cursor: 'pointer',
                    }} onClick={() => {
                        setOpenModal(false)
                    }}/>
                    <Typography variant={'h4'} fontWeight={"bold"}>
                        Invite Player To Your League
                    </Typography>
                    <Typography variant={'body2'} sx={{mt: 1}}>
                        Enter One Email At A Time
                    </Typography>
                    <Box component="form" noValidate onSubmit={() => {
                        setIsClicked(false)
                    }} sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Player Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Button
                            endIcon={<MailOutlineIcon/>}
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: "#486ce7",
                                minWidth: 'fit-content',
                                marginX: '8px',
                                color: "#ffffff",
                                borderRadius: 0,
                                ":hover": {
                                    backgroundColor: "#c40000",
                                    color: "#ffffff",
                                },
                                mt: 3
                            }}
                            disabled={isClicked}
                            onClick={() => {
                                setIsClicked(true);
                                invitePlayer((document.getElementById('email') as HTMLInputElement).value);
                            }}
                        >
                            Invite Player
                        </Button>
                    </Box>
                </Grid>
            </Modal>
            <Modal open={openModal2} onClose={() => {
                setOpenModal2(false)
            }} sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                background: 'transparent',
                boxShadow: 'shadow.5',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    flexWrap: 'nowrap',
                    padding: 2,
                    backgroundColor: 'background.paper',
                    maxHeight: '500px',
                    overflowY: 'auto',
                    width: '350px',
                    borderRadius: '5px',
                    position: 'relative',
                }}>
                    <CloseIcon sx={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        margin: '1rem',
                        cursor: 'pointer',
                    }} onClick={() => {
                        setOpenModal2(false)
                    }}/>
                    <Typography variant={'h5'} fontWeight={"bold"}>
                        Players In {league?.name}
                    </Typography>
                    <ul>
                        {(players)?.map((player: string) => {
                            return (
                                <li key={player.toString()}>{player}</li>
                            )
                        })}
                    </ul>
                </Grid>
            </Modal>
            <Modal open={showLeaveModal} onClose={() => {
                setShowLeaveModal(false)
            }} sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                background: 'transparent',
                boxShadow: 'shadow.5',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                {
                    isCreator ?
                        <Grid container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            flexWrap: 'nowrap',
                            padding: 3,
                            backgroundColor: 'background.paper',
                            maxHeight: 'fit-content',
                            overflowY: 'auto',
                            width: '350px',
                            borderRadius: '5px',
                            position: 'relative',
                        }}>
                            <CloseIcon sx={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                margin: '1rem',
                                cursor: 'pointer',
                            }} onClick={() => {
                                setShowLeaveModal(false)
                            }}/>
                            <Typography variant={'h5'} fontWeight={"bold"} marginTop={2}>
                                Please transfer ownership before leaving the league. If you do not list a new owner, the league will be deleted.
                            </Typography>
                            <FormControl variant="outlined" style={{ margin: '20px 0', minWidth: 120 }}>
                                <InputLabel id="nickname-select-label">Select Player</InputLabel>
                                <Select
                                    labelId="nickname-select-label"
                                    id="nickname-select"
                                    value={selectedNickname}
                                    onChange={(event) => {
                                        setSelectedNickname(event.target.value as string);
                                    }}
                                    label="Select Player"
                                >
                                    {filteredPlayers.map((player, index) => (
                                        <MenuItem key={index} value={player}>
                                            {player}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button variant="contained" color="error" onClick={handleConfirm} sx={{ mt: 2 }}>
                                Confirm
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleCancel} sx={{ mt: 2 }}>
                                Cancel
                            </Button>
                        </Grid> :
                        <Grid container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            flexWrap: 'nowrap',
                            padding: 3,
                            backgroundColor: 'background.paper',
                            maxHeight: 'fit-content',
                            overflowY: 'auto',
                            width: '350px',
                            borderRadius: '5px',
                            position: 'relative',
                        }}>
                            <CloseIcon sx={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                margin: '1rem',
                                cursor: 'pointer',
                            }} onClick={() => {
                                setShowLeaveModal(false)
                            }}/>
                            <Typography variant={'h5'} fontWeight={"bold"} marginTop={2}>
                                Are you sure you want to leave {league?.name}?
                            </Typography>
                            <Button variant="contained" color="error" onClick={handleConfirm} sx={{ mt: 2 }}>
                                Confirm
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleCancel} sx={{ mt: 2 }}>
                                Cancel
                            </Button>
                        </Grid>
                }
            </Modal>
            <Modal open={showRemoveModal} onClose={() => {
                setShowRemoveModal(false)
            }} sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                background: 'transparent',
                boxShadow: 'shadow.5',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    flexWrap: 'nowrap',
                    padding: 3,
                    backgroundColor: 'background.paper',
                    maxHeight: 'fit-content',
                    overflowY: 'auto',
                    width: '350px',
                    borderRadius: '5px',
                    position: 'relative',
                }}>
                    <CloseIcon sx={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        margin: '1rem',
                        cursor: 'pointer',
                    }} onClick={() => {
                        setShowRemoveModal(false)
                    }}/>
                    <Typography variant={'h5'} fontWeight={"bold"} marginTop={2}>
                        Please select the player you would like to remove from the league.
                    </Typography>
                    <FormControl variant="outlined" style={{ margin: '20px 0', minWidth: 120 }}>
                        <InputLabel id="nickname-select-label">Select Player</InputLabel>
                        <Select
                            labelId="nickname-select-label"
                            id="nickname-select"
                            value={selectedNickname}
                            onChange={(event) => {
                                console.log(event.target.value);
                                setSelectedNickname(event.target.value as string);
                            }}
                            label="Select Player"
                        >
                            {filteredPlayers.map((player, index) => (
                                <MenuItem key={index} value={player}>
                                    {player}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" color="error" onClick={handleKickConfirm} sx={{ mt: 2 }}>
                        Confirm
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleKickCancel} sx={{ mt: 2 }}>
                        Cancel
                    </Button>
                </Grid>
            </Modal>
            <Layout>
                {!loading ?
                    <Grid container sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: 'flex-start',
                        padding: 1,
                        maxWidth: "100vw",
                        overflowX: 'hidden',
                        position: 'relative',
                        minHeight: 'calc(100vh - 384px)',
                    }}>
                        <Grid container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            overflow: 'auto',
                            alignItems: 'center',
                            maxWidth: '100vw',
                            position: 'relative',
                        }}>
                            <Grid container component={Paper} elevation={3} padding={2} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                backgroundColor: 'background.paper',
                                margin: '8px',
                                padding: '8px',
                                borderRadius: '5px',
                                maxWidth: isMdOrUp ? '80vw' : '90vw'
                            }}>
                                <Stack direction={'row'} p={1}>
                                    {league?.image_url !== null &&
                                        <img src={league?.image_url} alt={league?.name} height={'75px'}/>}
                                    <Typography variant={isMdOrUp ? "h3" : "h5"} fontWeight={"bold"} p={1}>
                                        {league?.name}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} p={1} sx={{
                                    overflowX: 'auto',
                                    width: '100%',
                                    flexWrap: 'nowrap',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    {bracketsViewable &&
                                        (bracketsOpen ?
                                            <Button variant={'contained'} sx={{
                                                backgroundColor: "#486ce7",
                                                minWidth: 'fit-content',
                                                marginX: '8px',
                                                color: "#ffffff",
                                                ":hover": {
                                                    backgroundColor: "#c40000",
                                                    color: "#ffffff",
                                                }
                                            }} onClick={() => {
                                                if (league && event_id.length > 0) {
                                                    window.location.href = "/bracket/?" + encodeURIComponent("event_id=" + event_id + "&league_id=" + league.id + "&user_id=" + user_id);
                                                }
                                            }}>
                                                <Typography variant={'h6'} noWrap>
                                                    Fill Out Bracket
                                                </Typography>
                                            </Button>
                                            : eventActive ?
                                                <Button variant={'contained'} sx={{
                                                    minWidth: 'fit-content',
                                                    marginX: '8px',
                                                    backgroundColor: "#486ce7",
                                                    color: "#ffffff",
                                                    ":hover": {
                                                        backgroundColor: "#c40000",
                                                        color: "#ffffff",
                                                    }
                                                }} onClick={() => {
                                                    if (league && event_id.length > 0 && user_id) {
                                                        window.location.href = "/bracket/?" + encodeURIComponent("event_id=" + event_id + "&league_id=" + league.id + "&user_id=" + user_id);
                                                    }
                                                }}>
                                                    <Typography variant={'h6'} noWrap>
                                                        View Bracket
                                                    </Typography>
                                                </Button> : null)
                                    }

                                    <Button variant={'contained'} sx={{
                                        backgroundColor: "#486ce7",
                                        minWidth: 'fit-content',
                                        marginX: '8px',
                                        color: "#ffffff",
                                        ":hover": {
                                            backgroundColor: "#c40000",
                                            color: "#ffffff",
                                        }
                                    }} onClick={() => {
                                        setOpenModal(true)
                                    }}>
                                        <Typography variant={'h6'} noWrap>
                                            Invite Players
                                        </Typography>
                                    </Button>
                                    {players.length !== 0 &&
                                        <Button variant={'contained'} sx={{
                                            backgroundColor: "#486ce7",
                                            minWidth: 'fit-content',
                                            marginRight: '8px',
                                            color: "#ffffff",
                                            ":hover": {
                                                backgroundColor: "#c40000",
                                                color: "#ffffff",
                                            }
                                        }} onClick={() => {
                                            setOpenModal2(true)
                                        }}>
                                            <Typography variant={'h6'} noWrap>
                                                View Players
                                            </Typography>
                                        </Button>
                                    }
                                    <CopyToClipboardButton
                                        text_to_copy={league ? `You have been invited to join ${league?.name}! The invite code is ${league?.invite_code}` : ""}
                                        text_to_display={"Copy Invite Code"}
                                        style={{
                                            margin: '0, 8px',
                                            minWidth: 'fit-content',
                                            backgroundColor: "#486ce7",
                                            color: "#ffffff",
                                        }}
                                    />
                                    <Button variant={'contained'} sx={{
                                        backgroundColor: "#486ce7",
                                        minWidth: 'fit-content',
                                        marginLeft: '8px',
                                        color: "#ffffff",
                                        ":hover": {
                                            backgroundColor: "#c40000",
                                            color: "#ffffff",
                                        }
                                    }} onClick={() => {
                                        window.location.href = '/league/history/?' + encodeURIComponent(`league_id=${leagueId}`);
                                    }}>
                                        <HistoryIcon/>
                                        <Typography variant={'h6'} noWrap>
                                            Leaderboard History
                                        </Typography>
                                    </Button>
                                    <Button variant={'contained'} sx={{
                                        backgroundColor: "#486ce7",
                                        minWidth: 'fit-content',
                                        marginLeft: '8px',
                                        color: "#ffffff",
                                        ":hover": {
                                            backgroundColor: "#c40000",
                                            color: "#ffffff",
                                        }
                                    }} onClick={() => {
                                        setShowLeaveModal(true)
                                    }}>
                                        <LogoutIcon/>
                                        <Typography variant={'h6'} noWrap>
                                            Leave League
                                        </Typography>
                                    </Button>
                                    {isCreator &&
                                        <Button variant={'contained'} sx={{
                                            backgroundColor: "#486ce7",
                                            minWidth: 'fit-content',
                                            marginX: '8px',
                                            color: "#ffffff",
                                            ":hover": {
                                                backgroundColor: "#c40000",
                                                color: "#ffffff",
                                            }
                                        }} onClick={() => {
                                            setShowRemoveModal(true)
                                        }}>
                                            <RemoveCircleOutlineIcon/>
                                            <Typography variant={'h6'} noWrap>
                                                Remove Player
                                            </Typography>
                                        </Button>
                                    }
                                    {isCreator &&
                                        <Button variant={'contained'} sx={{
                                            backgroundColor: "#486ce7",
                                            minWidth: 'fit-content',
                                            marginX: '8px',
                                            color: "#ffffff",
                                            ":hover": {
                                                backgroundColor: "#c40000",
                                                color: "#ffffff",
                                            }
                                        }} onClick={() => {
                                            window.location.href = '/league/edit/?' + encodeURIComponent(`league_id=${leagueId}`);
                                        }}>
                                            <EditIcon/>
                                            <Typography variant={'h6'} noWrap>
                                                Edit League
                                            </Typography>
                                        </Button>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                        <AdSpot id={"league-detail-1"} sx={{m: 2, width: isMdOrUp ? '80vw' : '100vw'}}/>
                        <LeaderboardCarousel
                            league_name={league?.name!}
                            leaderboards={leaderboards}
                            league_id={league?.id.toString()!}
                            event_id={event_id}
                            sx={{justifyContent: 'center', m: 2, width: isMdOrUp ? '80vw' : '100vw'}}
                        />
                        <AdSpot id={"league-detail-2"} sx={{m: 2, width: isMdOrUp ? '80vw' : '100vw'}}/>
                    </Grid>
                    :
                    <Grid container sx={{height: '50vh'}}>
                        <Loader/>
                    </Grid>
                }
            </Layout>
        </>
    )
}