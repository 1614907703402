import React, {useState, useEffect} from 'react';
import {Grid, Snackbar, Alert, useTheme} from '@mui/material';
import Layout from "../utilities/Layout";
import TrackLeaderboardCarousel from "../components/leaderboard/TrackLeaderboardCarousel";
import {track_competition_load} from "../apis/ScreenLoad";
import {useMediaQuery, Theme} from '@mui/material';
import GA4EventSend from "../utilities/GA4EventSend";
import Typography from "@mui/material/Typography";
import TrackChallengeLogo from "../assets/images/TrackChallengeLogo.png";
import AdSpot from "../components/common/AdSpot";
import {TrackLeaderboardEntry} from "../components/leaderboard/TrackLeaderboard";
import Paper from "@mui/material/Paper";

export default function TrackCompetition() {
    const [isMdOrUp, setIsMdOrUp] = useState(
        useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [trackComp, setTrackComp] = useState<Array<TrackLeaderboardEntry>>([]);
    const [trackCompAvg, setTrackCompAvg] = useState<Array<TrackLeaderboardEntry>>([]);
    const [season, setSeason] = useState("2025 Season");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';

    let user_id: number = 0;
    try {
        user_id = JSON.parse(localStorage.getItem("user") as string).id;
    } catch {
        let pathname = encodeURIComponent(window.location.pathname);
        window.location.href = "/signin/redirect?path=" + pathname;
    }

    const mapToLeaderboardEntries = (data: any[]): TrackLeaderboardEntry[] => {
        let entries: TrackLeaderboardEntry[] = []
        data.forEach((item) => (
            entries.push({
                rank: item.rank,
                nickname: item.league_name,
                points: item.points,
                possible_points: item.possible_points,
                leagueInviteCode: item.league_invite_code,
            })));
        return entries;
    };

    useEffect(() => {
        if (loading) {
            track_competition_load().then((response) => {
                if (response.succeeded) {
                    setTrackComp(response.data["track_competition"]);
                    setTrackCompAvg(response.data["track_competition_average"]);
                    if(response.data.track_competition !== undefined && response.data.track_competition.length > 0) {
                        setSeason(response.data.track_competition[0].season);
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                }
                GA4EventSend("track_challenge", "load", "track_challenge", response.statusCode, false);
            });
        }

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [loading]);

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Layout>
            <Grid container spacing={3} textAlign={"center"} justifyContent={"center"} >
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={snackbarOpen}
                    onClose={handleSnackbarClose}
                    autoHideDuration={6000}
                >
                    <Alert
                        elevation={6}
                        severity={snackbarSeverity}
                        sx={{width: "100%"}}
                        variant="filled"
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Grid item xs={isMdOrUp ? 9 : 12}
                      sx={{
                          display: "flex", flexDirection: isMdOrUp ? "row" : "column",
                          position: 'relative', alignItems: "center", justifyContent: "center",
                      }}>
                    <img src={TrackChallengeLogo} alt={"Track Challenge Logo"}
                         style={{maxWidth: isMdOrUp ? 'min(50%, 400px)' : 'min(75%, 400px)', margin: '16px'}}/>
                    <Typography variant={"h6"} sx={{background: darkMode ? '#121212' : 'white', padding: '16px'}}>
                        The Track Challenge is an opportunity for tracks to foster community with their teams, drivers
                        and fans by competing against other NHRA tracks for DRBB glory. Don't see your local track?
                        Reach out to them <a href={"https://www.nhra.com/member-track-locator"}>HERE!</a>
                    </Typography>
                </Grid>
                <Grid item xs={12} justifyContent={"center"} sx={{display: "flex", paddingTop: '0 !important'}}>
                    <TrackLeaderboardCarousel
                        league_name={"Drag Race Bracket Bonanza"}
                        leaderboards={[
                            {
                                display_name: `${season} Total Points`,
                                entries: mapToLeaderboardEntries(trackComp),
                                viewable: true,
                                user_id: user_id,
                                setSnackbarOpen,
                                setSnackbarMessage,
                                setSnackbarSeverity
                            },
                            {
                                display_name: `${season} Average Points`,
                                entries: mapToLeaderboardEntries(trackCompAvg),
                                viewable: true,
                                user_id: user_id,
                                setSnackbarOpen,
                                setSnackbarMessage,
                                setSnackbarSeverity
                            },
                        ]}
                        setSnackbarOpen={setSnackbarOpen}
                        setSnackbarMessage={setSnackbarMessage}
                        setSnackbarSeverity={setSnackbarSeverity}
                        sx={{
                            justifyContent: "center",
                            width: isMdOrUp ? "80vw" : "100vw",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AdSpot id={'track-challenge-1'} sx={{m: '16px auto', width: '75%'}}/>
                </Grid>
            </Grid>
        </Layout>
    );
}
